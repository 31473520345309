
import YesNotSelection from "@/components/YesNotSelection.vue";
import Vue from "vue";
import stepFielfdMixin from "../stepFielfdMixin";

export default Vue.extend({
  name: "current-health-problems",
  components: {
    YesNotSelection,
  },
  mixins: [stepFielfdMixin],
  data() {
    return {
      fields: {
        highBloodP: false,
        heartDisease: false,
        chestPain: false,
        cardiacPacemaker: false,
        easilyWinded: false,
        rheumaticFever: false,
        heartMurmur: false,
        stroke: false,
        swollenAnkles: false,
        angina: false,
        faintingSeizures: false,
        asthma: false,
        lowBloodPressure: false,
        epilepsyConvulsions: false,
        leukemia: false,
        diabetes: false,
        kidneyDiseases: false,
        emphysema: false,
        cancer: false,
        arthritis: false,
        heartAttack: false,
        hepatitisJaundice: false,
        jointReplacementOrImplant: false,
        radiationTherapy: false,
        glaucoma: false,
        recentWeightLoss: false,
        liverDisease: false,
        heartTrouble: false,
        respiratoryProblems: false,
        aIDSorHIVinfection: false,
        thyroidProblem: false,
        sexuallyTransmittedDisease: false,
        stomachTroublesUlcers: false,
        othersHealthProblem: false,
        abnormalBleeding: false,
        bloodTransfusion: false,
        bloodDisorderAnemia: false,
        treatmentForTumorGrowth: false,
        previousDentalTreatment: false,
        dentalAppliances: false,
        anyOtherDisease: false,
        tuberculosis: false,
      },
    };
  },
});
